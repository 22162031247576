import { DataAccess } from "../dataaccess/data.access";
import { Cache } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { EventIncludeOption, EventService } from "./event.service";
import {
    Invoice,
    InvoiceUpdateData,
    EventPricingData,
    LineItem,
    LineItemI,
    MicroBilling,
    RateGroupListItem,
    TaxListItem,
    lineItemType,
} from "../pojo/Pricing";
import { Proto } from "../pojo/Proto";
import ISODateString = Proto.ISODateString;
import { TelemetryService } from "./telemetry.service";
import NumericalString = Proto.NumericalString;
import { EventI } from "../pojo/EventI";
import { ICheckoutRequest, PaymentService } from "../modules/s25-pricing/s25-payments/payment.service";
import { S25PricingUtil } from "../modules/s25-pricing/s25.pricing.util";

export class PricingService {
    @Timeout
    @Cache({ immutable: true, targetName: "PricingService" })
    public static getRatesAndTaxes() {
        return DataAccess.get<{
            root: { rate_groups: { rate_group: RateGroupListItem[] }; taxes: { tax: TaxListItem[] } };
        }>(DataAccess.injectCaller("/pricing/rates_taxes.json", "PricingService.getRatesAndTaxes")).then((data) => {
            // Force tax rate group list into array
            for (let tax of data?.root?.taxes?.tax || []) tax.rate_groups = S25Util.array.forceArray(tax.rate_groups);
            return data;
        });
    }

    //note: pricing data is NOT all combined like it is in getPricing with combineRelatedEvents set to true (for list)
    //this just returns all events and their pricing data separated into each event from the API
    public static getPricingRelatedEvents(eventId: number, includeRelatedEvents: boolean) {
        //billing: bill items
        //customers: organizations on event
        //profile: name and date info for profile on each event
        var includes: EventIncludeOption[] = ["billing", "customers", "profile"];
        if (includeRelatedEvents) {
            return EventService.getEventRelationships(eventId).then(function (rel) {
                if (rel && rel.content && rel.content.length) {
                    var eventIds = [eventId];
                    jSith.forEach(rel.content, function (_, c) {
                        eventIds.push(c.content_event_id);
                    });
                    return EventService.getEventsInclude(eventIds, includes);
                } else {
                    return EventService.getEventsInclude(eventId, includes);
                }
            });
        } else {
            return EventService.getEventsInclude(eventId, includes);
        }
    }

    public static getPricingAndEventsFromPricingSetId(eventId: number, evBillId: number) {
        return PricingService.getPricingFromPricingSetId(eventId, evBillId).then(function (pricingEventData) {
            return EventService.getEventsInclude(pricingEventData.eventIds, ["customers", "profile"]).then(
                function (events) {
                    jSith.forEach(events, function (_, event) {
                        event.bill_item = [];
                        jSith.forEach(pricingEventData.bill_item, function (_, billItem) {
                            if (parseInt(billItem.eventId) === parseInt(event.event_id)) {
                                event.bill_item.push(billItem);
                            }
                        });
                    });
                    return events;
                },
            );
        });
    }

    public static getPricingFromPricingSetId(eventId: number, evBillId: number, combineRelatedEvents?: boolean) {
        return PricingService.getPricingSet(evBillId).then(function (pricingSet) {
            return PricingService.getPricingFromPricingSet(eventId, pricingSet, combineRelatedEvents);
        });
    }

    public static getPricingFromPricingSet(eventId: number, pricingSet?: MicroBilling, combineRelatedEvents?: boolean) {
        if (!pricingSet?.data?.items?.[0]) return;

        let { rateGroups, rateSchedules, organizations, profiles, occurrences } = pricingSet.expandedInfo;
        const bill = pricingSet.data.items[0];
        const { billDefn, billing, id } = bill;

        if (!combineRelatedEvents) {
            const rsrvIds = billDefn.reservations
                .filter((rsrv) => rsrv.event_id === eventId)
                .map((rsrv) => rsrv.rsrv_id);
            occurrences = occurrences.filter((occ) => rsrvIds.includes(occ.rsrvId));
            billing.lineItems = billing.lineItems.filter((lineItem) => lineItem.eventId === eventId);
        }

        const groupNames = S25Util.fromEntries(rateGroups.map((group) => [group.rateGroup_id, group.rateGroupName]));
        const rateNames = S25Util.fromEntries(rateSchedules.map((schedule) => [schedule.rateId, schedule.rateName]));
        const orgNames = S25Util.fromEntries(organizations.map((org) => [org.organizationId, org.organizationName]));
        const profileNames = S25Util.fromEntries(profiles.map((profile) => [profile.profileId, profile.name]));
        const occNames = S25Util.fromEntries(occurrences.map((occ) => [occ.rsrvId, occ.occurrence]));

        let ret: any = {
            event_id: eventId,
            eventIds: S25Util.propertyGetAllUnique(bill, "eventId").filter(function (id: any) {
                return id > 0;
            }),
            eventsNeedingRefresh: [],
            organization: [],
            orgToEvents: {},
            event_history: [
                {
                    history_type_id: 4,
                    history_dt: billDefn.billDate,
                },
            ],
            bill_item: [],
            evBillId: id,
            occSubtotals: billing.subtotals[0].accountOccurrence,
            occurrences: occurrences,
            profileSubtotals: billing.subtotals[0].accountProfile,
            noOccs: !billDefn.includeSpaces && !billDefn.includeResources && !billDefn.includeEventType,
            content: [],
        };

        return S25Util.all({
            eventsNeedingRefresh: EventService.getEventsNeedingRefresh(ret.eventIds),
            allEventData: EventService.getEventsInclude(ret.eventIds, ["customers", "relationships"]),
        }).then(function (resp) {
            let eventIdMap: any = {};

            ret.eventsNeedingRefresh = resp.eventsNeedingRefresh;

            jSith.forEach(resp.allEventData, function (_, event) {
                eventIdMap[event.event_id] = event;
                event.content && ret.content.push(event.content);

                jSith.forEach(event.organization, function (_, org) {
                    let orgId = parseInt(org.organization_id);
                    let eventId = parseInt(event.event_id);
                    if (!ret.orgToEvents[orgId]) {
                        ret.orgToEvents[orgId] = {};
                    }
                    ret.orgToEvents[orgId][eventId] = true;

                    org.evBillId = id;
                    org.organization_id = orgId; //id as integer

                    ret.organization.push(org);
                });
            });

            ret.organization = S25Util.array.uniqueByProp(ret.organization, "organization_id");

            const eventId = billDefn.events[0];

            jSith.forEach(billing.lineItems, function (_, lineItem) {
                lineItem = S25Util.prettifyJson(S25Util.camelToSnakeObj(lineItem), {
                    item_id: "bill_item_id",
                    item_name: "bill_item_name",
                    item_type: "bill_item_type_id",
                    profile_id: "ev_dt_profile_id",
                    total: "total_charge",
                    tax: "total_tax",
                });

                lineItem.tax = lineItem.taxes ? Object.values(lineItem.taxes) : [];
                delete lineItem.taxes;

                lineItem.occurrences = lineItem.occurrences
                    ? Object.values(lineItem.occurrences).map((row: LineItemI) => ({
                          occurrence: occNames[row.rsrv_id],
                          adjustmentAmt: row.adjustment_amt,
                          adjustmentPercent: row.adjustment_percent,
                          adjustmentName: row.adjustment_name,
                          listPrice: row.list_price,
                          price: row.price,
                          rsrvId: row.rsrv_id,
                          total: row.total_charge,
                      }))
                    : [];

                lineItem.taxable_amt = lineItem.taxable_amt || lineItem.price;

                lineItem.eventId = eventId;
                lineItem.eventLocator = eventIdMap[lineItem.event_id]?.event_locator;
                lineItem.evBillId = id;

                if (S25Util.isDefined(lineItem.adjustment_percent)) {
                    lineItem.adjustment_percent = lineItem.adjustment_percent / 100 + "";
                }
                if (S25Util.isDefined(lineItem.adjustment_amt)) {
                    lineItem.adjustment_amt += "";
                }

                lineItem.charge_to_name = orgNames[lineItem.charge_to_id];
                lineItem.rate_group_name = groupNames[lineItem.rate_group_id];
                lineItem.rate_name = rateNames[lineItem.rate_id];
                lineItem.bill_profile_name = profileNames[lineItem.ev_dt_profile_id] || "";

                ret.bill_item.push(lineItem);
            });

            jSith.forEach(billing.adjustments, function (_, adjustment) {
                adjustment = S25Util.camelToSnakeObj(adjustment);
                adjustment.eventId = eventId; //add an event id here so we can tie it back to event when making pricing data from set
                adjustment.bill_item_id = adjustment.item_id;
                adjustment.bill_item_type_id = -1;
                adjustment.ev_dt_profile_id = -2;

                adjustment.total_tax = adjustment.total_tax || adjustment.tax;
                adjustment.tax = adjustment.taxes;
                delete adjustment.taxes;

                adjustment.evBillId = id;

                if (S25Util.isDefined(adjustment.adjustment_percent)) {
                    adjustment.adjustment_percent = adjustment.adjustment_percent / 100 + "";
                }
                if (S25Util.isDefined(adjustment.adjustment_amt)) {
                    adjustment.adjustment_amt += "";
                }

                adjustment.charge_to_name = orgNames[adjustment.charge_to_id];

                ret.bill_item.push(adjustment);
            });

            return ret;
        });
    }

    @Timeout
    public static getPricing(eventId: number, combineRelatedEvents: boolean) {
        var i = 0;
        //billing: bill items
        //text: old invoice data
        //history: bill date
        //customers: organizations on event
        var includes: EventIncludeOption[] = ["billing", "text", "history", "customers"];
        !combineRelatedEvents && includes.push("relationships"); //needed to show msg to user that related events exist if they wish to combine
        if (combineRelatedEvents) {
            return EventService.getEventRelationships(eventId).then(function (rel) {
                if (rel && rel.content && rel.content.length) {
                    var eventIds = [eventId];
                    for (i = 0; i < rel.content.length; i++) {
                        eventIds.push(rel.content[i].content_event_id);
                    }

                    return S25Util.all({
                        events: EventService.getEventsInclude(eventIds, includes),
                        eventsNeedingRefresh: EventService.getEventsNeedingRefresh(eventIds),
                    }).then(function (resp) {
                        var contextEvent = S25Util.propertyGetParentWithChildValue(resp.events, "event_id", eventId);
                        var relatedEvents = [];
                        for (i = 0; i < resp.events.length; i++) {
                            if (resp.events[i] && parseInt(resp.events[i].event_id) !== eventId) {
                                relatedEvents.push(resp.events[i]);
                            }
                        }

                        if (contextEvent) {
                            var orgToEvents: any = {}; //hash to track which orgs contain which events in the context and related events
                            if (!contextEvent.bill_item) {
                                contextEvent.bill_item = [];
                            }
                            if (!contextEvent.organization) {
                                contextEvent.organization = [];
                            }

                            jSith.forEach(contextEvent.bill_item, function (_, item) {
                                item.eventId = parseInt(contextEvent.event_id);
                                item.eventLocator = contextEvent.event_locator;
                            });

                            jSith.forEach(contextEvent.organization, function (_, org) {
                                //context orgs to context event
                                var orgId = parseInt(org.organization_id);
                                var eventId = parseInt(contextEvent.event_id);
                                if (!orgToEvents[orgId]) {
                                    orgToEvents[orgId] = {};
                                    orgToEvents[orgId][eventId] = true;
                                } else {
                                    orgToEvents[orgId][eventId] = true;
                                }
                            });

                            for (i = 0; i < relatedEvents.length; i++) {
                                var relatedEvent = relatedEvents[i];

                                relatedEvent.bill_item = relatedEvent.bill_item || [];
                                jSith.forEach(relatedEvent.bill_item, function (_, item) {
                                    item.eventId = parseInt(relatedEvent.event_id);
                                    item.eventLocator = relatedEvent.event_locator;
                                });
                                contextEvent.bill_item = contextEvent.bill_item.concat(relatedEvent.bill_item);

                                relatedEvent.organization = relatedEvent.organization || [];
                                jSith.forEach(relatedEvent.organization, function (_, org) {
                                    //related event org to related event
                                    var orgId = parseInt(org.organization_id);
                                    var eventId = parseInt(relatedEvent.event_id);
                                    if (!orgToEvents[orgId]) {
                                        orgToEvents[orgId] = {};
                                        orgToEvents[orgId][eventId] = true;
                                    } else {
                                        orgToEvents[orgId][eventId] = true;
                                    }
                                });
                                contextEvent.organization = contextEvent.organization.concat(relatedEvent.organization);
                            }
                            contextEvent.organization = S25Util.array.uniqueByProp(
                                contextEvent.organization,
                                "organization_id",
                            );
                            contextEvent.orgToEvents = orgToEvents;
                            contextEvent.eventIds = eventIds;
                            contextEvent.eventsNeedingRefresh = resp.eventsNeedingRefresh;
                            contextEvent.locatorMap =
                                resp.events?.reduce((acc: { [key: number]: string }, event: EventI) => {
                                    acc[event.event_id] = event.event_locator;
                                    return acc;
                                }, {}) ?? {};
                            return contextEvent;
                        }
                    });
                } else {
                    return S25Util.all({
                        eventData: EventService.getEventInclude(eventId, includes),
                        eventsNeedingRefresh: EventService.getEventsNeedingRefresh([eventId]),
                    }).then(function (resp) {
                        resp.eventData.eventIds = [eventId];
                        resp.eventData.eventsNeedingRefresh = resp.eventsNeedingRefresh;
                        return resp.eventData;
                    });
                }
            });
        } else {
            return S25Util.all({
                eventData: EventService.getEventInclude(eventId, includes),
                eventsNeedingRefresh: EventService.getEventsNeedingRefresh([eventId]),
            }).then(function (resp) {
                resp.eventData.eventIds = [eventId];
                resp.eventData.eventsNeedingRefresh = resp.eventsNeedingRefresh;
                return resp.eventData;
            });
        }
    }

    //put a sub total pricing item to an event (these items are located in the footer of an org list)
    public static putPricingSubtotalItem(
        eventData: any,
        orgId: number,
        isNew: boolean,
        adjustmentValue: any,
        adjustmentType: any,
        objId: number,
        eventId: number,
        reason: string,
        evBillId: number,
    ) {
        var op = isNew ? "add" : "update";
        return PricingService.putPricingLineItem(
            eventData,
            {
                bill_item_id: isNew ? null : objId,
            },
            null,
            null,
            adjustmentValue,
            adjustmentType,
            {
                organization_id: orgId,
            },
            eventId,
            reason,
            op,
            true,
            evBillId,
        );
    }

    //put pricing line item to event (these are located in an org list's body)
    @Timeout
    public static putPricingLineItem(
        eventData: any,
        billItem: any,
        rateGroupId: number,
        rateScheduleId: number,
        adjustmentValue: string,
        adjustmentType: any,
        orgObj: any,
        eventId: number,
        reason: any,
        opOverride: any,
        isAdjustment: true,
        evBillId: number,
    ) {
        var billItemId = billItem.bill_item_id;
        var billItemTypeId = billItem.bill_item_type_id;
        var profileId = billItem.ev_dt_profile_id;
        var chargeToId = orgObj.organization_id > 0 ? orgObj.organization_id : null;
        var adjustmentName = reason;
        rateGroupId = rateGroupId > 0 ? rateGroupId : null;
        var rateId = rateScheduleId > 0 ? rateScheduleId : null;

        var adjustmentPercent,
            adjustmentAmt,
            op = opOverride;

        //ANG-4959 force delete of existing GLOBAL adjustments when there's no comment and the value is 0
        if (isAdjustment && !parseFloat(adjustmentValue) && !reason && !!billItemId) {
            return PricingService.deletePricingAdjustment(eventId, evBillId, billItemId);
        }

        if (adjustmentType === "percentage") {
            adjustmentPercent = adjustmentValue;
        } else {
            adjustmentAmt = adjustmentValue;
        }

        var billItemArr = S25Util.propertyGet(eventData, "bill_item") || [];
        if (!op && billItemArr.length > 0) {
            //actually replace billItem with the newBillItem
            op = "update";
        } else if (!op) {
            op = "add";
        }

        let payload: any = {
            content: {
                data: [
                    {
                        op: op,
                        lineItems: [
                            {
                                profileId: profileId,
                                itemId: billItemId,
                                itemType: billItemTypeId,
                            },
                        ],
                    },
                ],
            },
        };

        let lineItem = payload.content.data[0].lineItems[0];
        S25Util.extend(lineItem, {
            chargeToId: chargeToId,
            adjustmentName: adjustmentName,
            rateGroupId: rateGroupId,
            rateId: rateId,
            adjustmentAmt: parseFloat(adjustmentAmt),
            adjustmentPct: parseFloat(adjustmentPercent),
        });

        if (isAdjustment) {
            payload.content.data[0].adjustments = payload.content.data[0].lineItems;
            payload.content.data[0].adjustments[0].billId = evBillId;
            delete payload.content.data[0].lineItems;
        } else {
            payload.content.data[0].lineItems[0].billId = evBillId;
        }

        evBillId && payload.content.data.push({ billIdList: [evBillId] });

        payload = S25Util.deleteUndefDeep(payload);

        if (evBillId) {
            TelemetryService.sendWithSub("Pricing", "PaymentMode", "AddAdjustment");
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItem",
                ),
                payload,
            );
        } else {
            TelemetryService.sendWithSub("Pricing", "StandardMode", "AddAdjustment");
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItem",
                ),
                payload,
            );
        }
    }

    // Delete a subtotal adjustment
    @Timeout
    public static deletePricingAdjustment(eventId: number, evBillId: number, adjustmentId: number) {
        if (evBillId) {
            let payload = {
                content: {
                    data: [
                        { op: "remove", adjustments: [{ itemId: adjustmentId, billId: evBillId }] },
                        { billIdList: [evBillId] },
                    ],
                },
            };
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.deletePricingAdjustment",
                ),
                payload,
            );
        }

        let payload = { content: { data: [{ op: "remove", adjustments: [{ itemId: adjustmentId }] }] } };
        return DataAccess.put(
            DataAccess.injectCaller(
                `/micro/event/${eventId}/billing.json?include=occurrences&expand=T`,
                "PricingService.deletePricingAdjustment",
            ),
            payload,
        );
    }

    public static putPricingLineItemAttr(
        eventId: number,
        attrName: string,
        attrValue: any,
        billItemId: number,
        billItemTypeId: number,
        profileId: number,
        evBillId: number,
    ) {
        let payload: any = {
            content: {
                data: [
                    {
                        op: "update",
                        lineItems: [{ profileId: profileId, itemId: billItemId, itemType: billItemTypeId }],
                    },
                ],
            },
        };
        payload.content.data[0].lineItems[0][attrName] = attrValue;

        if (evBillId) {
            payload.content.data[0].lineItems[0].billId = evBillId;
            payload.content.data.push({ billIdList: [evBillId] });
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItemAttr",
                ),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingLineItemAttr",
                ),
                payload,
            );
        }
    }

    public static putPricingLineItemChargeToId(
        eventId: number,
        chargeToId: number,
        billItemId: number,
        billItemTypeId: number,
        profileId: number,
        evBillId: number,
    ) {
        return PricingService.putPricingLineItemAttr(
            eventId,
            "chargeToId",
            chargeToId,
            billItemId,
            billItemTypeId,
            profileId,
            evBillId,
        );
    }

    public static putPricingLineItemRateGroup(
        eventId: number,
        rateGroupId: number,
        billItemId: number,
        billItemTypeId: number,
        profileId: number,
        evBillId: number,
    ) {
        return PricingService.putPricingLineItemAttr(
            eventId,
            "rateGroupId",
            rateGroupId,
            billItemId,
            billItemTypeId,
            profileId,
            evBillId,
        );
    }

    public static putPricingReservationItem(
        eventId: number,
        profileId: number,
        rsrvId: number,
        billItemId: number,
        billItemType: number,
        adjustmentType: "dollarAmt" | "percentage",
        adjustmentValue: number,
        description: string,
        evBillId: number,
        isNew: boolean,
    ) {
        let payload: any = {
            content: {
                data: [
                    {
                        op: isNew ? "add" : "update",
                        lineItems: [
                            {
                                profileId: profileId,
                                rsrvId: rsrvId,
                                itemId: billItemId,
                                itemType: billItemType,
                                adjustmentName: description,
                            },
                        ],
                    },
                ],
            },
        };

        if (adjustmentType === "percentage") {
            payload.content.data[0].lineItems[0].adjustmentPct = adjustmentValue;
        } else {
            payload.content.data[0].lineItems[0].adjustmentAmt = adjustmentValue;
        }

        if (evBillId) {
            payload.content.data[0].lineItems[0].billId = evBillId;
            payload.content.data.push({ billIdList: [evBillId] });
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingReservationItem",
                ),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingReservationItem",
                ),
                payload,
            );
        }
    }
    public static putPricingEventRateGroup(eventId: number, rateGroupId: number, evBillId: number) {
        let payload: any = { content: { data: [{ op: "all", fields: [{ rateGroupId: rateGroupId }] }] } };

        if (evBillId) {
            payload.content.data[0].fields[0].billId = evBillId;
            payload.content.data.push({ billIdList: [evBillId] });
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/billingCustom.json?include=occurrences&expand=T",
                    "PricingService.putPricingEventRateGroup",
                ),
                payload,
            );
        } else {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json?include=occurrences&expand=T",
                    "PricingService.putPricingEventRateGroup",
                ),
                payload,
            );
        }
    }

    public static putBillingCustom(updateData: Partial<InvoiceUpdateData>, evBillId: number): Promise<Invoice> {
        const payload = {
            content: {
                data: [
                    {
                        op: "update",
                        billId: evBillId,
                        ...updateData,
                    },
                ],
            },
        };
        return DataAccess.put(
            DataAccess.injectCaller("/micro/event/billingCustom.json", "PricingService.putBillingCustom"),
            payload,
        );
    }

    public static putInvoiceId(evBillId: number, orgId: number, invoiceId: string) {
        const payload = {
            content: {
                data: [
                    {
                        invoiceDetails: [{ billId: evBillId, acctId: orgId, invoiceId: invoiceId }],
                    },
                ],
            },
        };
        return DataAccess.put(
            DataAccess.injectCaller("/micro/event/billingCustom.json", "PricingService.putInvoiceId"),
            payload,
        );
    }

    //put modified pricing bill date to event (located in the summary block at the top of the pricing component)
    public static putPricingBillingDate(eventId: number, newDate: Date, evBillId: number) {
        if (evBillId) {
            return PricingService.putBillingCustom(
                { billDate: S25Util.date.toS25ISODateStrEndOfDay(S25Util.date.getDate(newDate)) },
                evBillId,
            );
        } else {
            const payload = {
                content: {
                    data: [
                        {
                            op: "all",
                            fields: [{ billDate: S25Util.date.toS25ISODateStrEndOfDay(S25Util.date.getDate(newDate)) }],
                        },
                    ],
                },
            };
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/micro/event/" + eventId + "/billing.json",
                    "PricingService.putPricingBillingDate",
                ),
                payload,
            );
        }
    }

    @Timeout
    public static getStandardPricingForEvent(eventIdArr: number[]) {
        return DataAccess.get<{
            content: { data: { items: Invoice[] }; id: number; updated: ISODateString };
        }>(
            DataAccess.injectCaller(
                "/micro/event/" + eventIdArr.join("+") + "/billing.json",
                "PricingService.getStandardPricingForEvent",
            ),
        ).then(function (data) {
            return (data && data.content && data.content.data && data.content.data.items) || [];
        });
    }

    @Timeout
    public static getStandardPricingForEventIncludeRateInfo(eventIdArr: number[]) {
        return DataAccess.get<{ content: MicroBilling }>(
            DataAccess.injectCaller(
                "/micro/event/" +
                    eventIdArr.join("+") +
                    "/billing.json?include=rate_info+organizations+profiles+occurrences&expand=T",
                "PricingService.getStandardPricingForEvent",
            ),
        ).then(function (data) {
            return data.content;
        });
    }

    @Timeout
    public static getPricingSetsForEvents(eventIds: (number | NumericalString)[]): Promise<any> {
        return DataAccess.get<{
            content: {
                data: {
                    requestId: number;
                    items: Invoice[];
                };
            };
        }>(
            DataAccess.injectCaller(
                "/micro/event/billingCustom.json?eventId=" +
                    eventIds.join("+") +
                    "&include=rate_info+occurrences+requirements+event_type&expand=T",
                "PricingService.getPricingSetsForEvents",
            ),
        ).then(
            function (data) {
                return (data && data.content) || [];
            },
            function () {
                return [];
            },
        );
    }

    @Timeout
    public static getPricingSet(evBillId: number) {
        return DataAccess.get<{ content: MicroBilling }>(
            DataAccess.injectCaller(
                `/micro/event/billingCustom.json?billId=${evBillId}&include=rate_info+organizations+profiles+occurrences&expand=T`,
                "PricingService.getPricingSet",
            ),
        ).then(function (data) {
            return data?.content;
        });
    }

    @Timeout
    public static deletePricingSet(evBillId: number) {
        TelemetryService.sendWithSub("Pricing", "Event", "SetDelete");
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/micro/event/" + evBillId + "/billingCustom.json",
                "PricingService.deletePricingSet",
            ),
        );
    }

    @Timeout
    public static postBillingCustom(name: string, eventIds: any, rsrvIds: any, reqIds: any, includeEventType: string) {
        const isRequirementsOrEventTypeOnly = !rsrvIds?.length;

        let payload = {
            content: {
                data: [
                    {
                        op: "params",
                        eventIdList: eventIds,
                        rsrvIdList: rsrvIds,
                        includeRequirements: reqIds?.length > 0,
                        includeSpaces: !isRequirementsOrEventTypeOnly,
                        includeResources: !isRequirementsOrEventTypeOnly,
                        requirementIdList: reqIds,
                        includeEventType: includeEventType,
                        billName: name,
                    },
                ],
            },
        };
        return DataAccess.post(
            DataAccess.injectCaller("/micro/event/billingCustom.json", "PricingService.postBillingCustom"),
            payload,
        );
    }

    // creates an invoice copy of standard pricing
    public static async postStandardInvoice(eventPricingData: EventPricingData) {
        const rsrvIds = eventPricingData.occurrences?.map((occ) => occ.rsrvId) ?? [];
        let reqIds: number[] = [];
        let subtotalAdjustments: LineItem[] = [];
        let payments: Partial<ICheckoutRequest>[] = [];

        eventPricingData.bill_item?.forEach((lineItem) => {
            const {
                adjustment_amt,
                adjustment_percent,
                adjustment_name,
                bill_item_id,
                bill_item_type_id,
                charge_to_id,
                paymentMapAction,
                total_charge,
            } = lineItem;

            if (bill_item_type_id === lineItemType.ADJUSTMENT) {
                if (!paymentMapAction || paymentMapAction.action === "map") {
                    subtotalAdjustments.push({
                        adjustmentAmt: adjustment_amt,
                        adjustmentPct:
                            adjustment_percent &&
                            (typeof adjustment_percent === "string"
                                ? +adjustment_percent.replace("%", "")
                                : adjustment_percent * 100),
                        adjustmentName: adjustment_name,
                        chargeToId: charge_to_id,
                    });
                } else if (paymentMapAction.action === "convert") {
                    payments.push({
                        productDescription: adjustment_name ?? "",
                        amountInCents: Math.abs((total_charge ?? 0) * 100), // if an adjustment representing a payment is negative, convert to positive payment value to deduct from balance
                        currency: "usd",
                        organizationId: charge_to_id,
                        eventId: eventPricingData.event_id,
                        type: paymentMapAction.type.toUpperCase(),
                        source: "manual",
                        paymentStatus: paymentMapAction.status,
                        dueDate: S25Util.date.toS25ISODateStrStartOfDay(paymentMapAction.dueDate ?? new Date()),
                        ...(paymentMapAction.datePaid && {
                            paymentSuccess: S25Util.date.toS25ISODateStrStartOfDay(paymentMapAction.datePaid),
                        }),
                    });
                }
            }
            if (bill_item_type_id === lineItemType.REQUIREMENT) reqIds.push(bill_item_id);
        });

        const newInvoice = await PricingService.postBillingCustom(
            S25PricingUtil.StandardInvoiceName,
            eventPricingData.eventIds,
            rsrvIds,
            reqIds,
            "true",
        );

        const newInvoiceId = newInvoice?.content?.data?.items[0]?.id;
        let promiseArr: Promise<void>[] = [];
        if (newInvoiceId) {
            if (subtotalAdjustments.length) {
                let payload = {
                    content: {
                        data: [
                            {
                                op: "add",
                                adjustments: subtotalAdjustments.map((adj) => ({ ...adj, billId: newInvoiceId })) ?? [],
                            },
                            {
                                billIdList: [newInvoiceId],
                            },
                        ],
                    },
                };
                payload = S25Util.deleteUndefDeep(payload);
                promiseArr.push(
                    DataAccess.put(
                        DataAccess.injectCaller(
                            "/micro/event/billingCustom.json?include=occurrences&expand=T",
                            "PricingService.postStandardInvoice",
                        ),
                        payload,
                    ),
                );
            }
            await Promise.all([
                ...promiseArr,
                ...payments.map((payment) =>
                    PaymentService.createPayment({ ...payment, evBillId: newInvoiceId } as ICheckoutRequest),
                ),
            ]);
        }
    }
}
